function setEqualHeight() {
    // $(".grid-product .grid-element-content-header-image").matchHeight();
    $(".grid-product .grid-element-title").matchHeight();
    $(".grid-product .product-options-wrapper").matchHeight();
    // $(".grid-product .productshortdesc").matchHeight();
    $(".grid-product .productshortattributes").matchHeight();
    $(".luna-vita .grid-element-content").matchHeight();
    $(".grid-product .nakup").matchHeight();
    $(".social-media-box").matchHeight();
    // $(".gpx-box").matchHeight();
    $(".gpx-upload").matchHeight();
    $(".footer-grid-content").matchHeight();
    $(".news-list-017 .title-wrapper").matchHeight();
    $(".news-list-017 .news-content-wrapper").matchHeight();
    $(".grid-menu-item-011-01").matchHeight();
    $(".grid-news-item-017 article").matchHeight();
    $(".grid-news-item-017 news-image-wrapper").matchHeight();
    $(".grid-news-item-017 news-content-wrapper").matchHeight();
    // $(".grid-menu-item-014, .prva-distance-filter .product-filter-distance-wrapper").matchHeight();
}

$(window).on("load", function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    setEqualHeight();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit = 140,
        initial_height = 140,
        target_height = 80,
        initial_padding = 15,
        target_padding = 10,
        initial_line_height = 120,
        target_line_height = 60,
        logo_height,
        padding,
        line_height,
        zakuha_initial_height = 190,
        zakuha_target_height = 130,
        zakuha_logo_initial_height = 250,
        zakuha_logo_target_height = 110,
        zakuha_height,
        zakuha_logo_height;

    switch (current_breakpoint) {
        case "xxlarge":
            scroll_limit = 140;
            initial_height = 140;
            target_height = 80;
            initial_padding = 15;
            initial_line_height = 120;
            zakuha_initial_height = 190;
            zakuha_target_height = 130;
            zakuha_logo_initial_height = 250;
            zakuha_logo_target_height = 110;
            break;
        case "xlarge":
            scroll_limit = 90;
            initial_height = 90;
            target_height = 70;
            initial_padding = 15;
            target_padding = 10;
            initial_line_height = 60;
            target_line_height = 50;
            zakuha_initial_height = 140;
            zakuha_target_height = 120;
            zakuha_logo_initial_height = 180;
            zakuha_logo_target_height = 100;
            break;
        case "large":
            scroll_limit = 80;
            initial_height = 80;
            target_height = 60;
            initial_padding = 15;
            target_padding = 5;
            initial_line_height = 50;
            target_line_height = 50;
            zakuha_initial_height = 130;
            zakuha_target_height = 110;
            zakuha_logo_initial_height = 165;
            zakuha_logo_target_height = 90;
            break;
        case "medium":
            scroll_limit = 60;
            initial_height = 60;
            target_height = 60;
            initial_padding = 5;
            target_padding = 5;
            initial_line_height = 50;
            target_line_height = 50;
            zakuha_initial_height = 60;
            zakuha_target_height = 60;
            zakuha_logo_initial_height = 80;
            zakuha_logo_target_height = 50;
            break;
        case "small":
            scroll_limit = 60;
            initial_height = 60;
            target_height = 60;
            initial_padding = 5;
            target_padding = 5;
            initial_line_height = 50;
            target_line_height = 50;
            zakuha_initial_height = 60;
            zakuha_target_height = 60;
            zakuha_logo_initial_height = 80;
            zakuha_logo_target_height = 50;
            break;
        case "xsmall":
            scroll_limit = 60;
            initial_height = 60;
            target_height = 60;
            initial_padding = 5;
            target_padding = 5;
            initial_line_height = 50;
            target_line_height = 50;
            zakuha_initial_height = 60;
            zakuha_target_height = 60;
            zakuha_logo_initial_height = 80;
            zakuha_logo_target_height = 50;
            break;
        default:
            scroll_limit = 0;
            initial_height = 90;
            target_height = 90;
            initial_padding = 20;
            target_padding = 20;
    }
    if (current_breakpoint_num > 3) {
        if ($(window).scrollTop() <= scroll_limit) {
            $('.page-blue .header-wrapper').removeClass('scroll-ended');
            logo_height = initial_height - ($(window).scrollTop() / scroll_limit) * (initial_height - target_height);
            padding = initial_padding - ($(window).scrollTop() / scroll_limit) * (initial_padding - target_padding);
            line_height = line_height - ($(window).scrollTop() / scroll_limit) * (initial_line_height - target_line_height);

            zakuha_height = zakuha_initial_height - ($(window).scrollTop() / scroll_limit) * (zakuha_initial_height - zakuha_target_height);
            zakuha_logo_height = zakuha_logo_initial_height - ($(window).scrollTop() / scroll_limit) * (zakuha_logo_initial_height - zakuha_logo_target_height);
        } else {
            logo_height = target_height;
            padding = target_padding;
            line_height = target_line_height;
            zakuha_height = zakuha_target_height;
            zakuha_logo_height = zakuha_logo_target_height;
            $('.page-blue .header-wrapper').addClass('scroll-ended');
        }
        $("#logo a").css("height", logo_height + "px");
        $("#logo a").css("padding-top", padding + "px");
        $("#logo a").css("padding-bottom", padding + "px");
        $("#logo img").css("height", (logo_height - 2 * padding) + "px");
        $("#logo-zakuha a").css("height", zakuha_height + "px");
        $("#logo-zakuha a img").css("height", zakuha_logo_height + "px");
        // $("#header-main-wrapper").css("padding-top", padding + "px");
        // $("#header-main-wrapper").css("padding-bottom", padding + "px");
    } else {
        if ($(window).scrollTop() <= scroll_limit) {
            $('.page-blue .header-wrapper').removeClass('scroll-ended');
            zakuha_height = zakuha_initial_height - ($(window).scrollTop() / scroll_limit) * (zakuha_initial_height - zakuha_target_height);
            zakuha_logo_height = zakuha_logo_initial_height - ($(window).scrollTop() / scroll_limit) * (zakuha_logo_initial_height - zakuha_logo_target_height);
        } else {
            zakuha_height = zakuha_target_height;
            zakuha_logo_height = zakuha_logo_target_height;
            $('.page-blue .header-wrapper').addClass('scroll-ended');
        }
        $("#logo a").css("height", "");
        $("#logo a").css("padding-top", "");
        $("#logo a").css("padding-bottom", "");
        $("#logo img").css("height", "");
        $("#logo a").css("line-height", "");
        $("#logo-zakuha a").css("height", zakuha_height + "px");
        $("#logo-zakuha a img").css("height", zakuha_logo_height + "px");
        // $("#header-main-wrapper").css("padding-top", "");
        // $("#header-main-wrapper").css("padding-bottom", "");
    }

});
/*
if ('serviceWorker' in navigator) {

    navigator.serviceWorker.getRegistrations().then(function(registrations) {

    for(let registration of registrations) {

            registration.unregister()

    }}).catch(function(err) {

        console.log('Service Worker registration failed: ', err);

    });
}
*/

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/serviceworker.min.js').then(function(reg) {

            if (reg.installing) {
                console.log('Service worker installing');
            } else if (reg.waiting) {
                console.log('Service worker installed');
            } else if (reg.active) {
                console.log('Service worker active');
            }

        }).catch(function(error) {
            // registration failed
            console.log('Registration failed with ' + error);
        });
    });
} else {
    console.log('No Service worker Available');
}
